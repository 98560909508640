.footer {
  padding-top: 72px;
  padding-bottom: 52px;
  
  margin-left: auto;
  margin-right: auto;
  font-family: 'Gilroy', sans-serif;
  color: $footer-text-color;
  @include font-style (400, 14px, 1.25);
  @media screen and (min-width: $desctop) {
  @include font-style (400, 16px, 1.25);
    }
    @media screen and (min-width: $tablet) {
      padding-top: 100px;
      padding-bottom: 50px;
    }
    @media screen and (min-width: $desctop) {
      padding-top: 170px;
      padding-bottom: 72px;
    }
}

.footer--container {
    //  margin-left:0;
    // margin-right: 0;
    // width: 100%;

    text-align: left;    
    @media screen and (min-width: $tablet) {        
        padding-left: 60px;
        padding-right: 60px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    align-items: flex-end;       
    }
    @media screen and (min-width: $desctop) {        
        padding-left: 190px;
        padding-right: 80px;
        }
}

.footer-mobile {
    display: flex;
    justify-content: space-between;    
    margin-bottom: 52px;
    @media screen and (min-width: $tablet) {
        margin-bottom: 0px;
      }
    @media screen and (min-width: $desctop) {
        justify-content: start;
      }
}
.footer-first{
    text-align: left;
        @media screen and (min-width: $tablet) {   
    margin-bottom: 0px;
    margin-right: 139px;
      } 
      @media screen and (min-width: $desctop) {
    display: flex;   
    margin-right: 50px;

      }

}
.footer-second{
    text-align: right;
          @media screen and (min-width: $tablet) {
    text-align: left;
      }
            @media screen and (min-width: $desctop) {
            display: flex;   
      }
}
.footer__select {
   @media screen and (min-width: $desctop) {
    display: flex;
    margin-bottom: 0px;
    margin-right: 0px;
      }   
      
}
 .footer-select__item:not(:last-child) {
  margin-bottom: 24px;
  @media screen and (min-width: $desctop) {
    margin-bottom: 0px;
    margin-right: 50px;
      }
    }
    
   .footer-select__item {     
  &:hover,
  &:focus {
    color: $accent-color;
    text-decoration: none;
    outline: transparent;
  }
}

.primary-footer {
  font-family: 'Roboto';
  @include font-style (400, 16px, 1.17);
  color: $text-color;
}