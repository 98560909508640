.page-header {
  position: relative;
}

.page-header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;

  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (min-width: $mobile) {
    width: $mobile;
  }

  @media screen and (min-width: $tablet) {
    height: 112px;
    padding-right: 60px;
    padding-left: 60px;
    width: $tablet;
  }

  @media screen and (min-width: $desctop) {
    height: 153px;
    padding-right: 80px;
    padding-left: 80px;
    width: $desctop;
  }
}

.logo {
  @media screen and (min-width: $desctop) {
    width: 130px;
    height: 15px;
  }
}

.menu-btn {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: $text-color;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: $pre-desctop) {
    display: none;
  }
}

.menu-btn__icon {
  fill: currentColor;
}

.icon-close {
  .menu-btn & {
    display: none;
  }

  .menu-btn.is-open & {
    display: block;
  }
}

.icon-menu {
  .menu-btn.is-open & {
    display: none;
  }
}

.menu-container {
  &.is-open {
    transform: translateY(0);
  }

  @media screen and (max-width: $pre-desctop) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: $background-color;

    transform: translateX(-100%);
    transition: transform 250ms $cubic-bezier;
  }

  @media screen and(min-width:$tablet) and(max-width:$pre-desctop) {
    height: 255px;
    box-shadow: 0px 80px 80px -80px #000000;
  }
}

.site-nav {
  padding-left: 20px;
  padding-top: 30px;

  @media screen and (min-width: $tablet) {
    padding-left: 60px;
  }

  @media screen and (min-width: $desctop) {
    padding: 0;
  }
}
.site-nav__list {
  @media screen and (min-width: $desctop) {
    display: flex;
  }
}

.site-nav__item {
  @media screen and (max-width: $pre-desctop) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media screen and (min-width: $desctop) {
    &:not(:last-child) {
      margin-right: 77px;
    }
  }
}

.site-nav__link {
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: #b5302d;
  }
}
