@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";
.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

@font-face {
  font-family: RobotoCondensed;
  src: url("RobotoCondensed-Regular.49ba6f22.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("Gilroy-Regular.902236a4.woff") format("woff"), url("Gilroy-Regular.53cdd398.woff2") format("woff2");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url("Gilroy-Semibold.83b64308.woff") format("woff"), url("Gilroy-Semibold.eff0d7ae.woff2") format("woff2");
}

body {
  color: rgba(255, 255, 255, .8);
  background-color: #191919;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.45;
  }
}

@media screen and (min-width: 1440px) {
  body {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 1440px;
  }
}

p, h1, h2, h3 {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
}

.title {
  text-transform: uppercase;
  color: #e7e7e7;
  letter-spacing: .02em;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1440px) {
  .title {
    font-size: 54px;
    font-weight: 600;
    line-height: 1.2;
  }
}

.subtitle {
  color: #e7e7e7;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
}

@media screen and (min-width: 1440px) {
  .subtitle {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.25;
  }
}

.page-header {
  position: relative;
}

.page-header__container {
  height: 74px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

@media screen and (min-width: 480px) {
  .page-header__container {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .page-header__container {
    height: 112px;
    width: 768px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .page-header__container {
    height: 153px;
    width: 1440px;
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .logo {
    width: 130px;
    height: 15px;
  }
}

.menu-btn {
  cursor: pointer;
  color: rgba(255, 255, 255, .8);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 0;
  padding: 0;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 1439px) {
  .menu-btn {
    display: none;
  }
}

.menu-btn__icon {
  fill: currentColor;
}

.menu-btn .icon-close {
  display: none;
}

.menu-btn.is-open .icon-close {
  display: block;
}

.menu-btn.is-open .icon-menu {
  display: none;
}

.menu-container.is-open {
  transform: translateY(0);
}

@media screen and (max-width: 1439px) {
  .menu-container {
    width: 100%;
    height: 100vh;
    background-color: #191919;
    transition: transform .25s cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateX(-100%);
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .menu-container {
    height: 255px;
    box-shadow: 0 80px 80px -80px #000;
  }
}

.site-nav {
  padding-top: 30px;
  padding-left: 20px;
}

@media screen and (min-width: 768px) {
  .site-nav {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .site-nav {
    padding: 0;
  }
}

@media screen and (min-width: 1440px) {
  .site-nav__list {
    display: flex;
  }
}

@media screen and (max-width: 1439px) {
  .site-nav__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .site-nav__item:not(:last-child) {
    margin-right: 77px;
  }
}

.site-nav__link {
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.site-nav__link:hover, .site-nav__link:focus {
  color: #b5302d;
}

.hero {
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .hero {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .hero {
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .hero__container {
    justify-content: end;
    padding: 0;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .hero__container {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .hero__wrapper {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .hero__wrapper {
    margin-right: 40px;
  }
}

.hero-list {
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .hero-list {
    margin-bottom: 105px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-list {
    margin-bottom: 94px;
  }
}

.hero-list__item:not(:last-child) {
  margin-bottom: 16px;
}

@media screen and (min-width: 1440px) {
  .hero-list__item:not(:last-child) {
    margin-bottom: 36px;
  }
}

.hero-list__descr {
  width: 222px;
}

.hero-list__descr--small {
  width: 93px;
}

@media screen and (min-width: 768px) {
  .hero-list__descr {
    width: 253px;
  }

  .hero-list__descr--small {
    width: 110px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-list__descr {
    width: 321px;
  }

  .hero-list__descr--small {
    width: 150px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-title__wrapper {
    padding-left: 132px;
  }
}

.hero__title {
  width: 234px;
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #e7e7e7;
  margin-bottom: 32px;
  font-size: 34px;
  font-weight: 600;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .hero__title {
    width: 289px;
    margin-bottom: 40px;
    font-size: 42px;
  }
}

@media screen and (min-width: 1440px) {
  .hero__title {
    width: 490px;
    margin-bottom: 86px;
    font-size: 74px;
  }
}

.hero__btn {
  width: 100%;
  letter-spacing: .02em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, .9);
  cursor: pointer;
  background-color: #df3d3a;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 50%, #b5302d 50%);
  background-size: 200% 100%;
  border: none;
  padding: 19px 113px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  transition: background-position .3s cubic-bezier(.19, 1, .22, 1) .1s, color .5s, background-color .5s;
}

.hero__btn:hover, .hero__btn:focus {
  background-color: #b5302d;
  background-position: -100% 100%;
}

@media screen and (max-width: 767.98px) {
  .hero__btn {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 768px) {
  .hero__btn {
    padding: 19px 120px;
  }
}

@media screen and (min-width: 1440px) {
  .hero__btn {
    width: 400px;
    padding: 22px 166px;
    font-size: 20px;
  }
}

.design-section {
  padding-top: 0;
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .design-section {
    padding-top: 0;
    padding-bottom: 128px;
  }
}

@media screen and (min-width: 1440px) {
  .design-section {
    padding-top: 0;
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .title-cont {
    width: 294px;
  }
}

@media screen and (min-width: 1440px) {
  .title-cont {
    width: 510px;
  }
}

@media screen and (min-width: 768px) {
  .design__container {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 0;
    display: flex;
  }
}

.design__title {
  margin-bottom: 32px;
}

@media screen and (min-width: 1440px) {
  .design__title {
    margin-bottom: 50px;
  }
}

.design__subtitle {
  margin-bottom: 16px;
}

@media screen and (min-width: 1440px) {
  .design__subtitle {
    margin-bottom: 20px;
  }
}

.design__list {
  margin-bottom: 24px;
}

.design__list--bottom {
  margin-bottom: 32px;
}

@media screen and (min-width: 1440px) {
  .design__list {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .design__picture {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .design__picture {
    margin-right: 150px;
  }
}

.ability-section {
  padding-top: 0;
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .ability-section {
    padding-top: 0;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .ability-section {
    padding-top: 0;
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .ability__container {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .ability__container {
    padding-left: 190px;
  }
}

.ability__title {
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .ability__title {
    width: 150px;
  }
}

@media screen and (min-width: 1440px) {
  .ability__title {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .ability__list {
    height: 127px;
    flex-flow: column wrap;
    display: flex;
  }
}

.ability__item {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1439px) {
  .ability__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.ability__item:before {
  content: "";
  width: 13px;
  height: 13px;
  background: #df3d3a;
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
}

@media screen and (min-width: 1440px) {
  .ability__item:not(:nth-child(3n)) {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sound-section {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .sound-section {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .sound-section {
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .sound-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .sound-container {
    padding-right: 80px;
  }

  .sound-container.sound-list {
    padding-left: 190px;
    padding-right: 190px;
  }
}

.sound-title {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .sound-title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .sound-title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

.sound-img {
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 1440px) {
  .sound-img {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.sound-list {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .sound-list {
    flex-direction: row;
    justify-content: space-between;
  }
}

.sound-list__title {
  padding-bottom: 15px;
}

@media screen and (min-width: 1440px) {
  .sound-list__title {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .sound-list__item {
    max-width: 294px;
  }
}

@media screen and (min-width: 1440px) {
  .sound-list__item {
    max-width: 510px;
  }
}

@media screen and (max-width: 767px) {
  .sound-list__item:not(last-child) {
    margin-bottom: 42px;
  }
}

.technology {
  background-color: #191919;
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .technology .container {
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .technology__wrapp {
    margin-right: 60px;
    padding-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .technology__wrapp {
    margin-right: 170px;
    padding-left: 190px;
  }
}

.technology__wrapper {
  flex-shrink: 0;
}

.technology__title {
  margin-bottom: 32px;
}

@media screen and (min-width: 1440px) {
  .technology__title {
    margin-bottom: 50px;
  }
}

.technology__subtitle {
  margin-bottom: 16px;
  font-size: 20px;
}

@media screen and (min-width: 1440px) {
  .technology__subtitle {
    margin-bottom: 20px;
    font-size: 28px;
  }
}

.technology__text {
  color: rgba(255, 255, 255, .8);
}

@media screen and (max-width: 768px) {
  .technology__text {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1440px) {
  .technology__text {
    font-size: 20px;
  }
}

.technology__picture {
  width: 100%;
}

.guarantees {
  background-color: #191919;
  margin: 0 auto;
  padding-bottom: 52px;
}

@media screen and (min-width: 480px) {
  .guarantees {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .guarantees {
    width: 768px;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .guarantees {
    width: 1440px;
    background-color: #191919;
    background-image: url("sinus-desktop.d70bf838.svg");
    background-position: 104% 72%;
    background-repeat: no-repeat;
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .guarantees__container {
    flex-shrink: 0;
    padding: 0 60px;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .guarantees__wrap {
    order: 2;
    margin-left: 60px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1440px) {
  .guarantees__wrap {
    margin-left: 150px;
  }
}

.guarantees__title {
  margin-bottom: 69px;
}

@media screen and (min-width: 1440px) {
  .guarantees__title {
    margin-bottom: 87px;
  }
}

.guarantees__subtitle {
  margin-bottom: 16px;
}

@media screen and (min-width: 1440px) {
  .guarantees__subtitle {
    margin-bottom: 20px;
  }
}

.guarantees__item:not(:last-child) {
  margin-bottom: 61px;
}

@media screen and (min-width: 768px) {
  .guarantees__item:not(:last-child) {
    margin-bottom: 92px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1440px) {
  .guarantees__item:not(:last-child) {
    margin-bottom: 106px;
  }
}

.guarantees__item {
  margin-bottom: 32px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .guarantees__item {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) and (min-width: 1440px) {
  .guarantees__item {
    width: 400px;
    margin-bottom: 0;
  }
}

.guarantees__item:before {
  content: "";
  width: 13px;
  height: 13px;
  background-color: #df3d3a;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -37px;
  left: 0;
}

@media screen and (min-width: 768px) {
  .guarantees__picture {
    flex-shrink: 0;
    margin-left: -60px;
  }
}

.history {
  margin: 0 auto;
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .history {
    width: 768px;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .history {
    width: 1440px;
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .history__container {
    flex-flow: column wrap;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .history__container {
    flex-flow: column wrap;
    padding-left: 190px;
    padding-right: 190px;
    display: flex;
  }
}

.history__wrap {
  justify-content: space-between;
  display: flex;
}

.history__title {
  width: 124px;
}

@media screen and (min-width: 768px) {
  .history__title {
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .history__title {
    position: relative;
    top: 0;
    left: 0;
  }
}

.history__icon {
  margin-right: -20px;
}

@media screen and (min-width: 768px) {
  .history__icon {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .history__icon {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .history__picture {
    flex-shrink: 0;
    margin-left: 60px;
    margin-right: -60px;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .history__picture {
    flex-shrink: 0;
    margin-left: 150px;
    margin-right: -190px;
    display: flex;
  }
}

.history__description {
  color: rgba(255, 255, 255, .8);
  margin: 32px 0;
}

@media screen and (min-width: 768px) {
  .history__description {
    width: 294px;
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .history__description {
    width: 400px;
    margin: 50px 0;
    position: relative;
    top: 0;
    left: 0;
  }
}

.history__quote-text {
  color: #e7e7e7;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
}

@media screen and (min-width: 1440px) {
  .history__quote-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.25;
  }
}

.history__caption {
  color: rgba(255, 255, 255, .4);
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
}

@media screen and (min-width: 1440px) {
  .history__caption {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: .7;
  }
}

.history__quote:before {
  content: "";
  height: 25px;
  width: 34px;
  background-image: url("quote.ffb70970.svg");
  margin: 32px 0 24px;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .history__quote:before {
    margin: 0 0 52px;
  }
}

@media screen and (min-width: 1440px) {
  .history__quote:before {
    margin: 0 0 50px;
  }
}

@media screen and (min-width: 768px) {
  .history__figure {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .history__figure {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .history__quote-wrapper {
    width: 294px;
    margin-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .history__quote-wrapper {
    width: 400px;
    margin-left: 260px;
  }
}

.history__figure, .history__quote {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .history__figure, .history__quote {
    margin: -120px 0 0;
  }
}

@media screen and (min-width: 1440px) {
  .history__figure, .history__quote {
    margin: -120px 0 0;
  }
}

@media screen and (min-width: 768px) {
  .history__order {
    height: 766px;
    flex-flow: column wrap;
    align-items: flex-start;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .history__order {
    height: 877px;
    flex-flow: column wrap;
    align-items: flex-start;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .history__picture.order3 {
    order: 3;
  }
}

@media screen and (min-width: 1440px) {
  .history__picture.order3 {
    order: 3;
  }
}

@media screen and (min-width: 768px) {
  .history__description.order4 {
    order: 0;
    margin: 85px 0 120px;
  }
}

@media screen and (min-width: 1440px) {
  .history__description.order4 {
    order: 0;
    margin: 74px 0 120px;
  }
}

@media screen and (min-width: 768px) {
  .history__description.order2 {
    order: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .history__description.order5 {
    order: 5;
    margin-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .history__description.order5 {
    width: 510px;
    order: 5;
    margin-left: 150px;
  }
}

.reviews {
  padding-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .reviews {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .reviews {
    padding-bottom: 170px;
  }
}

.slider-reviews {
  position: relative;
}

.reviews-slide__wrapper {
  width: 100%;
  margin-top: 32px;
}

.reviews-slide__title {
  color: #df3d3a;
  margin-bottom: 16px;
}

@media screen and (max-width: 1439px) {
  .reviews-slide__text {
    margin-bottom: 32px;
  }
}

.slider-reviews .swiper-pagination {
  width: fit-content;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 1070px;
}

@media screen and (max-width: 1439px) {
  .slider-reviews .swiper-pagination {
    position: static;
  }
}

.slider-reviews .swiper-pagination-bullet {
  color: #df3d3a;
  opacity: .2;
  width: auto;
  height: auto;
  background: none;
  border: 0 solid;
  border-radius: 0;
  font-size: 28px;
  line-height: 1.25;
  position: relative;
}

.slider-reviews .swiper-pagination-bullet:nth-child(-n+2) {
  margin-right: 30px;
}

.slider-reviews .swiper-pagination-bullet:nth-child(-n+2):after {
  content: "";
  width: 7px;
  height: 1px;
  background-color: #df3d3a;
  position: absolute;
  top: 50%;
  right: -19px;
  transform: translateY(-50%);
}

.slider-reviews .swiper-pagination-bullet-active:after, .slider-reviews .swiper-pagination-bullet-active {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .reviews-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .reviews-container {
    padding-left: 0;
  }
}

@media screen and (min-width: 1440px) {
  iframe {
    width: 920px;
    height: 590px;
  }
}

@media screen and (max-width: 767px) {
  iframe {
    width: 100%;
    height: 180px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  iframe {
    height: 417px;
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .slider-title {
    position: absolute;
    top: 0;
    left: 1070px;
  }

  .reviews-slide {
    display: flex;
  }

  .reviews-slide__wrapper {
    max-width: 258px;
    margin-top: 105px;
    margin-left: 150px;
  }
}

@media screen and (max-width: 1439px) {
  .slider-title {
    margin-bottom: 32px;
  }
}

.faq {
  background-color: #191919;
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .faq {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .faq {
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .faq .container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .faq .container {
    padding-left: 190px;
    padding-right: 190px;
  }
}

.faq__title {
  color: #e7e7e7;
  text-transform: uppercase;
  margin-bottom: 32px;
}

@media screen and (min-width: 1440px) {
  .faq__title {
    margin-bottom: 50px;
  }
}

.faq__list {
  counter-reset: section;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.faq__item {
  border-bottom: 2px solid rgba(255, 255, 255, .1);
  position: relative;
}

.faq__body {
  padding-top: 24px;
  padding-bottom: 14px;
}

@media screen and (min-width: 768px) {
  .faq__body {
    padding-bottom: 27px;
  }
}

.faq__body-padding {
  padding-top: 0;
}

.faq__body-item {
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
  position: relative;
}

@media screen and (max-width: 767px) {
  .faq__body-item {
    padding-right: 50px;
  }
}

@media screen and (min-width: 768px) {
  .faq__body-item {
    justify-content: flex-start;
  }
}

.faq__body-item:before {
  color: #e7e7e7;
  counter-increment: section;
  content: "0" counter(section);
  margin-right: 16px;
  font-family: RobotoCondensed;
  font-size: 20px;
  font-weight: 400;
  line-height: .7;
}

@media screen and (min-width: 1440px) {
  .faq__body-item:before {
    margin-right: 32px;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.25;
  }
}

.faq__text {
  color: rgba(255, 255, 255, .8);
  font-size: 14px;
  line-height: 1.3;
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .faq__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.45;
  }
}

@media screen and (min-width: 1440px) {
  .faq__text {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.25;
  }
}

.faq__button {
  width: 32px;
  height: 32px;
  color: #e7e7e7;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .1);
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: RobotoCondensed;
  font-size: 20px;
  font-weight: 400;
  line-height: .7;
  display: flex;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 479px) {
  .faq__button {
    margin-left: 12px;
  }
}

@media screen and (min-width: 768px) {
  .faq__button {
    right: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .faq__button {
    align-self: flex-start;
  }
}

@media screen and (min-width: 1440px) {
  .faq__button {
    width: 42px;
    height: 42px;
    font-size: 28px;
    font-weight: 400;
    line-height: .7;
  }
}

.faq__content {
  opacity: 1;
  max-height: 100%;
  color: rgba(255, 255, 255, .8);
  margin-top: 10px;
  padding-left: 36px;
  padding-right: 44px;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 1440px) {
  .faq__content {
    padding-left: 60px;
  }
}

.faq__item.hide .faq__content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .order-section {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 768px) {
  .order-section {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .order-section {
    padding-bottom: 170px;
  }
}

.order-container {
  position: relative;
}

@media screen and (max-width: 768px) {
  .order-container {
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) {
  .order-container {
    padding: 0 60px;
  }
}

@media screen and (min-width: 1440px) {
  .order-container {
    padding: 0 0 0 190px;
  }
}

@media screen and (min-width: 1440px) {
  .order__content-wrap {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .order-title {
    text-align: center;
  }
}

.hidden {
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.radio-label {
  width: 58px;
  height: 58px;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .radio-label {
    margin-top: 330px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 768px) {
  .radio-label {
    margin-top: 510px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .radio-label {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.dark {
  background-color: #000;
  margin-right: 20px;
}

.light {
  background-color: #fff;
}

.radio-img {
  opacity: 0;
  height: auto;
  pointer-events: none;
  position: absolute;
}

@media screen and (max-width: 1439px) {
  .radio-img {
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1440px) {
  .radio-img {
    top: 0;
    right: 0;
  }
}

.radio-input:checked + .radio-label {
  border: 5px solid #df3d3a;
}

.radio-input:checked + .radio-label .radio-img {
  opacity: 1;
}

.order-list {
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .order-list {
    margin-bottom: 50px;
  }
}

.order-list__item {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.order-list__item:first-child {
  border-top: 1px solid rgba(255, 255, 255, .1);
}

.order-list__button {
  width: 100%;
  cursor: pointer;
  background-color: #191919;
  border: none;
  justify-content: space-between;
  padding: 25px 0;
  display: flex;
}

.order-list__button-text {
  color: rgba(255, 255, 255, .8);
  font-size: 16px;
  line-height: 1.2;
}

.price {
  text-transform: uppercase;
  letter-spacing: .02em;
  color: #df3d3a;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .price {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .price {
    margin-bottom: 32px;
    font-size: 54px;
  }
}

.order-button {
  cursor: pointer;
  width: 100%;
  color: rgba(255, 255, 255, .9);
  background-color: #df3d3a;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 50%, #b5302d 50%);
  background-size: 200% 100%;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 22px 0;
  transition: background-position .3s cubic-bezier(.19, 1, .22, 1) .1s, color .5s, background-color .5s;
  display: flex;
}

.order-button:hover, .order-button:focus {
  background-color: #b5302d;
  background-position: -100% 100%;
}

@media screen and (min-width: 768px) {
  .order-button {
    width: 300px;
  }
}

@media screen and (min-width: 1440px) {
  .order-button {
    width: 100%;
  }
}

.order-button__text {
  letter-spacing: .02em;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

@media screen and (min-width: 768px) {
  .order-button__text {
    font-size: 20px;
  }
}

.page-poster {
  width: 100%;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 768px) {
  .page-poster {
    margin-bottom: 100px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .page-poster {
    width: 1440px;
    margin-bottom: 170px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .feedback--container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .feedback--container {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 190px;
    padding-right: 190px;
    display: flex;
  }
}

.feedback__image {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.feedback__contain {
  width: 280px;
  margin-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .feedback__contain {
    width: 445px;
    margin-bottom: 52px;
  }
}

@media screen and (min-width: 1440px) {
  .feedback__contain {
    width: 400px;
    margin-bottom: 0;
  }
}

.feedback__title {
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #e7e7e7;
  margin-bottom: 32px;
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .feedback__title {
    font-size: 30px;
  }
}

@media screen and (min-width: 1440px) {
  .feedback__title {
    font-size: 54px;
  }
}

.feedback__subtitle {
  color: rgba(255, 255, 255, .8);
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

@media screen and (min-width: 768px) {
  .feedback__subtitle {
    color: #e7e7e7;
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .feedback__subtitle {
    font-size: 28px;
  }
}

.form {
  width: 100%;
  color: rgba(255, 255, 255, .3);
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 1440px) {
  .form {
    width: 510px;
  }
}

.form-field {
  flex-direction: column;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.form-field input {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(255, 255, 255, .1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  outline: rgba(0, 0, 0, 0);
  margin-bottom: 32px;
  padding-bottom: 17px;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1), color .25s cubic-bezier(.4, 0, .2, 1);
}

.form-field input:hover, .form-field input:focus {
  color: rgba(255, 255, 255, .8);
  border-bottom: 2px solid #df3d3a;
  outline: rgba(0, 0, 0, 0);
}

.form-field.comment textarea {
  color: inherit;
  resize: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(255, 255, 255, .1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 60px;
}

.form-field.comment textarea:hover, .form-field.comment textarea:focus {
  color: rgba(255, 255, 255, .8);
  border-bottom: 2px solid #df3d3a;
  outline: rgba(0, 0, 0, 0);
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.icon {
  z-index: -1;
  margin: 0 0 1px;
  position: absolute;
}

.policy {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .policy {
    margin-bottom: 52px;
  }
}

.policy label {
  color: rgba(255, 255, 255, .8);
  cursor: pointer;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
}

@media screen and (min-width: 1440px) {
  .policy label {
    font-size: 16px;
    line-height: 1.6;
  }
}

.policy-link {
  color: inherit;
  margin-left: 4px;
  text-decoration: underline;
}

.policy-link:focus {
  color: inherit;
  outline: 1px solid #df3d3a;
  margin-left: 4px;
}

.checkbox {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  position: absolute;
}

.check-icon {
  width: 30px;
  height: 28px;
  vertical-align: middle;
  background-color: #191919;
  border: 2px solid #e9edfa;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 29px 0 0;
  padding-bottom: 9px;
  padding-left: 6px;
  display: inline-flex;
  position: relative;
}

.checkbox:checked + .check-icon {
  z-index: 100;
  background-color: #191919;
  background-size: contain;
  background-origin: content-box;
  border-color: #e9edfa;
}

.button--feedback {
  width: 100%;
  height: 58px;
  cursor: pointer;
  letter-spacing: .02em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, .9);
  vertical-align: middle;
  background: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 0 2px #df3d3a;
}

@media screen and (min-width: 768px) {
  .button--feedback {
    width: 294px;
  }
}

@media screen and (min-width: 1440px) {
  .button--feedback {
    min-width: 510px;
  }
}

.button--feedback:before, .button--feedback:after {
  box-sizing: inherit;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.draw {
  transition: color .25s;
}

.draw:before, .draw:after {
  width: 0;
  height: 0;
  border: 1px solid rgba(0, 0, 0, 0);
}

.draw:before {
  top: 0;
  left: 0;
}

.draw:after {
  bottom: 0;
  right: 0;
}

.draw:hover {
  color: rgba(255, 255, 255, .9);
}

.draw:hover:before, .draw:hover:after {
  width: 100%;
  height: 100%;
}

.draw:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
  transition: width .25s ease-out, height .25s ease-out .25s;
}

.draw:hover:after {
  transition: rgba(255, 255, 255, .9) border-color 0s ease-out .5s, width .25s ease-out .5s, h height .25s ease-out .75s;
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
}

.meet:hover {
  color: #df3d3a;
}

.meet:after {
  top: 0;
  left: 0;
}

.meet:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
}

.meet:hover:after {
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
  transition: height .25s ease-out, width .25s ease-out .25s;
}

.footer {
  color: rgba(255, 255, 255, .3);
  margin-left: auto;
  margin-right: auto;
  padding-top: 72px;
  padding-bottom: 52px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
}

@media screen and (min-width: 1440px) {
  .footer {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .footer {
    padding-top: 170px;
    padding-bottom: 72px;
  }
}

.footer--container {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .footer--container {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .footer--container {
    padding-left: 190px;
    padding-right: 80px;
  }
}

.footer-mobile {
  justify-content: space-between;
  margin-bottom: 52px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .footer-mobile {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .footer-mobile {
    justify-content: start;
  }
}

.footer-first {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .footer-first {
    margin-bottom: 0;
    margin-right: 139px;
  }
}

@media screen and (min-width: 1440px) {
  .footer-first {
    margin-right: 50px;
    display: flex;
  }
}

.footer-second {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .footer-second {
    text-align: left;
  }
}

@media screen and (min-width: 1440px) {
  .footer-second {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .footer__select {
    margin-bottom: 0;
    margin-right: 0;
    display: flex;
  }
}

.footer-select__item:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (min-width: 1440px) {
  .footer-select__item:not(:last-child) {
    margin-bottom: 0;
    margin-right: 50px;
  }
}

.footer-select__item:hover, .footer-select__item:focus {
  color: #df3d3a;
  outline: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.primary-footer {
  color: rgba(255, 255, 255, .8);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.17;
}

.backdrop {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  background-color: rgba(210, 210, 210, .514);
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .backdrop {
    padding: 93px 15px 94px;
    overflow-y: scroll;
  }
}

.modal {
  background-color: #191919;
  border-radius: 4px;
  padding: 50px 40px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .2);
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%)scale(1.1);
}

@media screen and (min-width: 768px) {
  .modal {
    width: 450px;
    height: 600px;
    transition: transform .25s cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)scale(1);
  }
}

@media screen and (min-width: 1440px) {
  .modal {
    width: 600px;
  }
}

.modal__btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: rgba(255, 255, 255, .9);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal__btn:hover, .modal__btn:focus {
  fill: #df3d3a;
}

.modal-btn__icon {
  fill: currentColor;
}

.form {
  flex-direction: column;
  display: flex;
}

.item-wrapper {
  flex-direction: column;
  margin-bottom: 50px;
  display: flex;
}

.form__group {
  width: 100%;
  margin-top: 10px;
  padding: 15px 0 0;
  position: relative;
}

.form__field {
  resize: none;
  width: 100%;
  color: #fff;
  background: none;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, .1);
  outline: 0;
  padding: 7px 0;
  font-size: 16px;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.form__field::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form__field::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form__field:placeholder-shown ~ .form__label {
  cursor: text;
  font-size: 16px;
  top: 20px;
}

.form__label {
  color: #9b9b9b;
  font-size: 14px;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
}

.form__field:focus {
  border-width: 3px;
  border-image: linear-gradient(to right, #df3d3a, #df3d3a) 1;
  padding-bottom: 6px;
  font-weight: 700;
}

.form__field:focus ~ .form__label {
  color: #df3d3a;
  font-size: 14px;
  font-weight: 700;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: -3px;
}

.form__field:required, .form__field:invalid {
  box-shadow: none;
}

.form-check {
  margin-bottom: 70px;
}

.form-check__item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-check__field {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  position: absolute;
}

.icon-box {
  width: 30px;
  height: 28px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, .8);
  margin-right: 8px;
  display: block;
  position: relative;
}

.form__icon {
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
}

.form-check__field:checked + .icon-box .form__icon {
  display: block;
}

.form-check__desrciption {
  color: #e9edfa;
  font-size: 16px;
  line-height: 1.62;
}

.form-check__link {
  text-underline-offset: 3px;
  text-decoration: underline;
}

.form-check__link:hover, .form-check__link:focus {
  color: #df3d3a;
}

.form__btn {
  cursor: pointer;
  box-sizing: border-box;
  color: rgba(255, 255, 255, .9);
  font-size: inherit;
  text-transform: uppercase;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 1em;
  padding: 1em 2em;
  font-weight: 700;
  position: relative;
  box-shadow: inset 0 0 0 2px #df3d3a;
}

.form__btn:before, .form__btn:after {
  box-sizing: inherit;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.draw {
  transition: color .25s;
}

.draw:before, .draw:after {
  width: 0;
  height: 0;
  border: 2px solid rgba(0, 0, 0, 0);
}

.draw:before {
  top: 0;
  left: 0;
}

.draw:after {
  bottom: 0;
  right: 0;
}

.draw:hover, .draw:focus {
  color: rgba(255, 255, 255, .9);
}

.draw:hover:before, .draw:hover:after {
  width: 100%;
  height: 100%;
}

.draw:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
  transition: width .25s ease-out, height .25s ease-out .25s;
}

.draw:hover:after {
  transition: rgba(255, 255, 255, .9) border-color 0s ease-out .5s, width .25s ease-out .5s, height .25s ease-out .75s;
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
}

.meet:hover, .meet:focus {
  color: #df3d3a;
}

.meet:after {
  top: 0;
  left: 0;
}

.meet:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
}

.meet:hover:after {
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
  transition: height .25s ease-out, width .25s ease-out .25s;
}

.backdrop {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  background-color: rgba(210, 210, 210, .514);
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .backdrop {
    padding: 93px 15px 94px;
    overflow-y: scroll;
  }
}

.modal {
  background-color: #191919;
  border-radius: 4px;
  padding: 50px 40px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .2);
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%)scale(1.1);
}

@media screen and (min-width: 768px) {
  .modal {
    width: 450px;
    height: 600px;
    transition: transform .25s cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)scale(1);
  }
}

@media screen and (min-width: 1440px) {
  .modal {
    width: 600px;
  }
}

.modal__btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: rgba(255, 255, 255, .9);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal__btn:hover, .modal__btn:focus {
  fill: #df3d3a;
}

.modal-btn__icon {
  fill: currentColor;
}

.form {
  flex-direction: column;
  display: flex;
}

.item-wrapper {
  flex-direction: column;
  margin-bottom: 50px;
  display: flex;
}

.form__group {
  width: 100%;
  margin-top: 10px;
  padding: 15px 0 0;
  position: relative;
}

.form__field {
  resize: none;
  width: 100%;
  color: #fff;
  background: none;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, .1);
  outline: 0;
  padding: 7px 0;
  font-size: 16px;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.form__field::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form__field::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form__field:placeholder-shown ~ .form__label {
  cursor: text;
  font-size: 16px;
  top: 20px;
}

.form__label {
  color: #9b9b9b;
  font-size: 14px;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
}

.form__field:focus {
  border-width: 3px;
  border-image: linear-gradient(to right, #df3d3a, #df3d3a) 1;
  padding-bottom: 6px;
  font-weight: 700;
}

.form__field:focus ~ .form__label {
  color: #df3d3a;
  font-size: 14px;
  font-weight: 700;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: -3px;
}

.form__field:required, .form__field:invalid {
  box-shadow: none;
}

.form-check {
  margin-bottom: 70px;
}

.form-check__item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-check__field {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  position: absolute;
}

.icon-box {
  width: 30px;
  height: 28px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, .8);
  margin-right: 8px;
  display: block;
  position: relative;
}

.form__icon {
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
}

.form-check__field:checked + .icon-box .form__icon {
  display: block;
}

.form-check__desrciption {
  color: #e9edfa;
  font-size: 16px;
  line-height: 1.62;
}

.form-check__link {
  text-underline-offset: 3px;
  text-decoration: underline;
}

.form-check__link:hover, .form-check__link:focus {
  color: #df3d3a;
}

.form__btn {
  cursor: pointer;
  box-sizing: border-box;
  color: rgba(255, 255, 255, .9);
  font-size: inherit;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 1em;
  padding: 1em 2em;
  font-weight: 700;
  position: relative;
  box-shadow: inset 0 0 0 2px #df3d3a;
}

.form__btn:before, .form__btn:after {
  box-sizing: inherit;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.draw {
  transition: color .25s;
}

.draw:before, .draw:after {
  width: 0;
  height: 0;
  border: 2px solid rgba(0, 0, 0, 0);
}

.draw:before {
  top: 0;
  left: 0;
}

.draw:after {
  bottom: 0;
  right: 0;
}

.draw:hover, .draw:focus {
  color: rgba(255, 255, 255, .9);
}

.draw:hover:before, .draw:hover:after {
  width: 100%;
  height: 100%;
}

.draw:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
  transition: width .25s ease-out, height .25s ease-out .25s;
}

.draw:hover:after {
  transition: rgba(255, 255, 255, .9) border-color 0s ease-out .5s, width .25s ease-out .5s, height .25s ease-out .75s;
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
}

.meet:hover, .meet:focus {
  color: #df3d3a;
}

.meet:after {
  top: 0;
  left: 0;
}

.meet:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
}

.meet:hover:after {
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
  transition: height .25s ease-out, width .25s ease-out .25s;
}

body.overflow-hidden {
  overflow: hidden;
}

.backdrop-stores {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 100;
  background-color: rgba(255, 255, 255, .2);
  transition: visibility .25s cubic-bezier(.4, 0, .2, 1), opacity .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.backdrop-stores.is-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.modal-stores {
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: sticky;
}

.modal-stores__body {
  max-width: 480px;
  width: 100%;
  background-color: #191919;
  border-radius: 12px;
  margin: 75px 45px;
  padding: 30px 15px 30px 30px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .2);
}

@media screen and (min-width: 768px) {
  .modal-stores__body {
    max-width: 768px;
  }
}

.modal-stores__close {
  height: 1px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid;
  margin-left: auto;
  display: flex;
  position: sticky;
  top: 5px;
  right: 15px;
}

.modal-stores__desc {
  width: 380px;
  margin: 15px 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45;
}

@media screen and (min-width: 768px) {
  .modal-stores__desc {
    width: 500px;
  }
}

.modal-stores__icon {
  width: 120px;
  height: 120px;
  fill: #df3d3a;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
}

.modal-stores__icon:hover, .modal-stores__icon:focus {
  fill: rgba(255, 255, 255, .8);
}

@media screen and (min-width: 768px) {
  .modal-stores__icon {
    width: 180px;
    height: 180px;
  }
}

.modal-stores__wrapper {
  color: rgba(255, 255, 255, .8);
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.modal-stores__title {
  color: #df3d3a;
  color: #df3d3a;
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.45;
}

.modal-stores__number {
  margin-bottom: 20px;
  font-family: RobotoCondensed;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
}

.modal-stores__link {
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.modal-stores__link:hover, .modal-stores__link:focus {
  color: #df3d3a;
}

.modal-stores__text {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.45;
}

body.overflow-hidden {
  overflow: hidden;
}

.backdrop-payment {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 100;
  background-color: rgba(255, 255, 255, .2);
  transition: visibility .25s cubic-bezier(.4, 0, .2, 1), opacity .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.backdrop-payment.is-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.modal-payment {
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: sticky;
}

.modal-payment__body {
  max-width: 480px;
  width: 100%;
  background-color: #191919;
  border-radius: 12px;
  margin: 75px 45px;
  padding: 30px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .2);
}

@media screen and (min-width: 768px) {
  .modal-payment__body {
    max-width: 768px;
  }
}

.modal-payment__close {
  height: 1px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid;
  margin-left: auto;
  display: flex;
  position: sticky;
  top: 5px;
  right: 15px;
}

.modal-payment__desc {
  width: 380px;
  margin: 15px 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45;
}

@media screen and (min-width: 768px) {
  .modal-payment__desc {
    width: 500px;
  }
}

.modal-payment__icon {
  width: 120px;
  height: 120px;
  fill: #df3d3a;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
}

.modal-payment__icon:hover, .modal-payment__icon:focus {
  fill: rgba(255, 255, 255, .8);
}

@media screen and (min-width: 768px) {
  .modal-payment__icon {
    width: 180px;
    height: 180px;
  }
}

.modal-payment__wrapper {
  color: rgba(255, 255, 255, .8);
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.modal-payment__title {
  color: #df3d3a;
  color: #df3d3a;
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.45;
}

.modal-payment__number {
  margin-bottom: 20px;
  font-family: RobotoCondensed;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
}

.modal-payment__link {
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
}

.modal-payment__link:hover, .modal-payment__link:focus {
  color: #df3d3a;
}

.modal-payment__text {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.45;
}

.backdrop {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  background-color: rgba(210, 210, 210, .514);
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .backdrop {
    padding: 93px 15px 94px;
    overflow-y: scroll;
  }
}

.modal {
  background-color: #191919;
  border-radius: 4px;
  padding: 50px 40px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .2);
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%)scale(1.1);
}

@media screen and (min-width: 768px) {
  .modal {
    width: 450px;
    height: 600px;
    transition: transform .25s cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)scale(1);
  }
}

@media screen and (min-width: 1440px) {
  .modal {
    width: 600px;
  }
}

.modal__btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: rgba(255, 255, 255, .9);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal__btn:hover, .modal__btn:focus {
  fill: #df3d3a;
}

.modal-btn__icon {
  fill: currentColor;
}

.form {
  flex-direction: column;
  display: flex;
}

.item-wrapper {
  flex-direction: column;
  margin-bottom: 50px;
  display: flex;
}

.form__group {
  width: 100%;
  margin-top: 10px;
  padding: 15px 0 0;
  position: relative;
}

.form__field {
  resize: none;
  width: 100%;
  color: #fff;
  background: none;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, .1);
  outline: 0;
  padding: 7px 0;
  font-size: 16px;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.form__field::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form__field::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form__field:placeholder-shown ~ .form__label {
  cursor: text;
  font-size: 16px;
  top: 20px;
}

.form__label {
  color: #9b9b9b;
  font-size: 14px;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
}

.form__field:focus {
  border-width: 3px;
  border-image: linear-gradient(to right, #df3d3a, #df3d3a) 1;
  padding-bottom: 6px;
  font-weight: 700;
}

.form__field:focus ~ .form__label {
  color: #df3d3a;
  font-size: 14px;
  font-weight: 700;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: -3px;
}

.form__field:required, .form__field:invalid {
  box-shadow: none;
}

.form-check {
  margin-bottom: 70px;
}

.form-check__item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-check__field {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  position: absolute;
}

.icon-box {
  width: 30px;
  height: 28px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, .8);
  margin-right: 8px;
  display: block;
  position: relative;
}

.form__icon {
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
}

.form-check__field:checked + .icon-box .form__icon {
  display: block;
}

.form-check__desrciption {
  color: #e9edfa;
  font-size: 16px;
  line-height: 1.62;
}

.form-check__link {
  text-underline-offset: 3px;
  text-decoration: underline;
}

.form-check__link:hover, .form-check__link:focus {
  color: #df3d3a;
}

.form__btn {
  cursor: pointer;
  box-sizing: border-box;
  color: rgba(255, 255, 255, .9);
  font-size: inherit;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 1em;
  padding: 1em 2em;
  font-weight: 700;
  position: relative;
  box-shadow: inset 0 0 0 2px #df3d3a;
}

.form__btn:before, .form__btn:after {
  box-sizing: inherit;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.draw {
  transition: color .25s;
}

.draw:before, .draw:after {
  width: 0;
  height: 0;
  border: 2px solid rgba(0, 0, 0, 0);
}

.draw:before {
  top: 0;
  left: 0;
}

.draw:after {
  bottom: 0;
  right: 0;
}

.draw:hover, .draw:focus {
  color: rgba(255, 255, 255, .9);
}

.draw:hover:before, .draw:hover:after {
  width: 100%;
  height: 100%;
}

.draw:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
  transition: width .25s ease-out, height .25s ease-out .25s;
}

.draw:hover:after {
  transition: rgba(255, 255, 255, .9) border-color 0s ease-out .5s, width .25s ease-out .5s, height .25s ease-out .75s;
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
}

.meet:hover, .meet:focus {
  color: #df3d3a;
}

.meet:after {
  top: 0;
  left: 0;
}

.meet:hover:before {
  border-top-color: rgba(255, 255, 255, .9);
  border-right-color: rgba(255, 255, 255, .9);
}

.meet:hover:after {
  border-bottom-color: rgba(255, 255, 255, .9);
  border-left-color: rgba(255, 255, 255, .9);
  transition: height .25s ease-out, width .25s ease-out .25s;
}

/*# sourceMappingURL=index.c0aed3c2.css.map */
