.order-section {
  @media screen and (max-width: 767px) {
    padding-bottom: 90px;
  }
  @media screen and (min-width: 768px) {
    padding-bottom: 100px;
  }
  @media screen and (min-width: 1440px) {
    padding-bottom: 170px;
  }
}

.order-container {
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
  @media screen and (min-width: 768px) {
    padding: 0 60px;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 0 0 190;
  }
}

.order__content-wrap {
  @media screen and (min-width: 1440px) {
    width: 400px;
  }
}

.order-title {
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.radio-label {
  display: inline-block;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin-top: 330px;
    margin-bottom: 32px;
  }
  @media screen and (min-width: 768px) {
    margin-top: 510px;
    margin-bottom: 40px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.dark {
  background-color: #000;
  margin-right: 20px;
}

.light {
  background-color: #fff;
}

.radio-img {
  position: absolute;
  opacity: 0;
  height: auto;
  pointer-events: none;

  @media screen and (max-width: 1439px) {
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 1440px) {
    right: 0;
    top: 0;
  }
}

.radio-input:checked + .radio-label {
  // border-color: $accent-color;
  border: 5px solid $accent-color;
}

.radio-input:checked + .radio-label .radio-img {
  opacity: 1;
}

.order-list {
  margin-bottom: 32px;
  @media screen and (min-width: 768px) {
    margin-bottom: 50px;
  }
}

.order-list__item {
  border: none;
  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.order-list__button {
  display: flex;
  justify-content: space-between;
  border: none;
  padding: 25px 0;
  background-color: $background-color;
  width: 100%;
  cursor: pointer;

 
}

.order-list__button-text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.2;
}

.price {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: $accent-color;

  margin-bottom: 25px;

  @media screen and (min-width: 768px) {
    font-size: 30px;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 54px;
    margin-bottom: 32px;
  }
}

.order-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 22px 0;
  width: 100%;

  border: none;
  background-color: $accent-color;
    color: $button-text-color;
  background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, #b5302d 50%);
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;
 
  

  &:hover,
  &:focus {
    background-color: #b5302d;
      background-position: -100% 100%;
  }

  @media screen and (min-width: 768px) {
    width: 300px;
  }
  @media screen and (min-width: 1440px) {
    width: 100%;
  }
}

.order-button__text {
  font-weight: 600;
  font-size: 16px;

  line-height: 1.25;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
}

