.design-section {
    @include section-paddings(0, 52px);
    @media screen and (min-width:$tablet) {
        @include section-paddings(0, 128px);
    }
    @media screen and (min-width:$desctop) {
        @include section-paddings(0, 170px);
    }
    }

.title-cont {
    @media screen and (min-width:$tablet) {
        width: 294px;
    }
    @media screen and (min-width:$desctop) {
        width: 510px;
    }
}
.design__container {
    @media screen and (min-width:$tablet)  {
    display: flex;
    flex-direction: row-reverse;
    justify-content:flex-end;
    padding-left: 0;
    }
}
.design__title {
    margin-bottom: 32px;
    @media screen and (min-width: $desctop) {
        margin-bottom: 50px;
    }
}
.design__subtitle {
    margin-bottom: 16px;
    @media screen and (min-width: $desctop) {
        margin-bottom: 20px;
    }
}
.design__list {
    margin-bottom: 24px;
    &--bottom {
        margin-bottom: 32px;
    }
    @media screen and (min-width: $desctop) {
        margin-bottom: 40px;
    }
}
.design__picture {
    @media screen and (min-width:$tablet) {
    margin-right: 60px;
}
    @media screen and (min-width: $desctop) {
    margin-right: 150px;
    }
}
