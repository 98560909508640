.guarantees {
  padding-bottom: 52px;
  margin: 0 auto;
  background-color: $background-color;
  @media  screen and (min-width: $mobile) {
    width: 480px;
  }
  @media  screen and (min-width: $tablet) {
    width: 768px;
    padding-bottom: 100px;
  }
  @media screen and (min-width: $desctop) {
    width: 1440px;
    padding-bottom: 170px;
    background-image: url(../images/sinus-desktop.svg);
    background-position: 104% 72%;
    background-repeat: no-repeat;
    background-color: $background-color; 
  }
}

.guarantees__container {
  @media screen and (min-width: $tablet) {
    padding: 0 60px;
    display: flex;
    flex-shrink: 0;
    
  } 
}

.guarantees__wrap {
  @media screen and (min-width: $tablet) {
    // width: 400px;
    margin-left: 60px;
    order: 2;
    @media screen and (min-width: $desctop) {
      margin-left: 150px;
    }
  }
}

.guarantees__title {
  margin-bottom: 69px;
  @media screen and (min-width: $desctop) {
    margin-bottom: 87px;
  }
}

.guarantees__subtitle {
  margin-bottom: 16px;
  @media screen and (min-width: $desctop) {
  margin-bottom: 20px;
}
}

// .guarantees__descriptions {
// }

.guarantees__item {
    &:not(:last-child) {
      margin-bottom: 61px; 
      @media screen and (min-width: $tablet) {
        margin-bottom: 92px;
      @media screen and (min-width: $desctop) {
        margin-bottom: 106px;
    }  }  }
}

.guarantees__item { 
  position: relative;
  margin-bottom: 32px;
  @media screen and (min-width: $tablet) {
    margin-bottom: 0;
  @media screen and (min-width: $desctop) {
    margin-bottom: 0;
    width: 400px;
  }
}}

.guarantees__item { 
  &::before {
  content: "";
  position: absolute;
  top: -37px;
  left: 0;
  display: block;
  width: 13px;
  border-radius: 50%;
  height: 13px;
  background-color: $accent-color;
  }
    }

    .guarantees__picture {
      @media screen and (min-width: $tablet) {
         flex-shrink: 0;
         margin-left: -60px;


      }
      
    }
