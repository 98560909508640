

.history {
  padding-bottom: 52px;
  margin: 0 auto;

  @media  screen and (min-width: $tablet) {
    width: 768px;
    padding-bottom: 100px;
  }

  @media screen and (min-width: $desctop) {
    width: 1440px;
    padding-bottom: 170px;
  }
}

.history__container {
@media screen and (min-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media screen and (min-width: $desctop) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 190px;
    padding-right: 190px;
}}

.history__wrap {
  display: flex;
  justify-content: space-between;
}

.history__title {
  width: 124px;
  @media screen and (min-width: $tablet) {
    position: relative;
    left: 0;
    top: 0;
}
@media screen and (min-width: $desctop) {
  position: relative;
    left: 0;
    top: 0;
}}

.history__icon {
  margin-right: -20px;
  @media screen and (min-width: $tablet) {
    display: none;
  }
  @media screen and (min-width: $desctop) {
    display: none;
  }
}

.history__picture {
  @media screen and (min-width: $tablet) {
    margin-right: -60px;
    margin-left: 60px;
    flex-shrink: 0;
    display: flex;
  }
  @media screen and (min-width: $desctop) {
    margin-right: -190px;
    margin-left: 150px;
    flex-shrink: 0;
    display: flex;
}}

.history__description {
  margin: 32px 0;
  color: rgba(255, 255, 255, 0.8);
  @media screen and (min-width: $tablet) {
    width: 294px;
    position: relative;
    top: 0;
    left: 0;
  }
  @media screen and (min-width: $desctop) {
    margin: 50px 0;
    width: 400px;
    position: relative;
    top: 0;
    left: 0;
}}


.history__quote-text {
  @include font-style (600, 20px, 1.25);
  color: $titles-color;
  @media screen and (min-width: $desctop) {
    @include font-style (600, 28px, 1.25);
}}

.history__caption { 
  margin-top: 16px;
  @include font-style (400, 14px, 1.25);
 color: rgba(255, 255, 255, 0.4);
 @media screen and (min-width: $desctop) {
  margin-top: 32px;
  @include font-style (400, 16px, .7);
}}

.history__quote::before {
  content:'';
  margin: 32px 0 24px; 
  background-image: url(../images/quote.svg);  
  display: inline-block;  
  height: 25px;  
  width: 34px; 
  @media screen and (min-width: $tablet) {
    margin: 0 0 52px;
  }
  @media screen and (min-width: $desctop) {
    margin: 0 0 50px;
} }

.history__figure {
  @media screen and (min-width: $tablet) {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
@media screen and (min-width: $desctop) {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}}

.history__quote-wrapper {
  @media screen and (min-width: $tablet) {
  margin-left: 60px;
  width: 294px;
}
@media screen and (min-width: $desctop) {
  margin-left: 260px;
  width: 400px;
}}


.history__figure,
.history__quote {
  margin: 0;
  @media screen and (min-width: $tablet) {
  margin: -120px 0 0;
}
@media screen and (min-width: $desctop) {
  margin: -120px 0 0;
}}

.history__order {
  @media screen and (min-width: $tablet) {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 766px;
}
@media screen and (min-width: $desctop) {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 877px;
}}

.history__picture.order3 {
  @media screen and (min-width: $tablet) {
  order: 3;
}
@media screen and (min-width: $desctop) {
  order: 3;
}}

.history__description.order4 {
  @media screen and (min-width: $tablet) {
  order: 0;
  margin: 85px 0 120px;
}
@media screen and (min-width: $desctop) {
  order: 0;
  margin: 74px 0 120px;
}}

.history__description.order2 {
  @media screen and (min-width: $tablet) {
  order: 0;
  margin-bottom: 0;
}
// @media screen and (min-width: $desctop) {
//   order: 0;
//   margin-bottom: 120px;
// }
}

.history__description.order5 {
  @media screen and (min-width: $tablet) {
  order: 5;
  margin-left: 60px;
  // width: 294px;
}
@media screen and (min-width: $desctop) {
  order: 5;
  margin-left: 150px;
  width: 510px;
}}

