.faq {
  padding-bottom: 52px;
  background-color: #191919;
  @media screen and (min-width: $tablet) {
    padding-bottom: 100px;
  }
  @media screen and (min-width: $desctop) {
    padding-bottom: 170px;
  }

  & .container {
    @media screen and (min-width: $tablet) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @media screen and (min-width: $desctop) {
      padding-left: 190px;
      padding-right: 190px;
    }
  }
}

.faq__title {
  color: $titles-color;
  text-transform: uppercase;
  margin-bottom: 32px;

  @media screen and (min-width: $desctop) {
    margin-bottom: 50px;
  }
}

.faq__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

  list-style-type: none;
  counter-reset: section;
}

.faq__item {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  position: relative;
}

.faq__body {
  padding-top: 24px;
  padding-bottom: 14px;

  @media screen and (min-width: $tablet) {
    padding-bottom: 27px;
  }
}

.faq__body-padding {
  padding-top: 0;
}

.faq__body-item {
  @include centered-flex(flex, baseline, flex-start);
  position: relative;
 @media screen and (max-width: $pre-tablet) {
  padding-right: 50px;
 }
  @media screen and (min-width: $tablet) {
    justify-content: flex-start;
  }
}

.faq__body-item::before {
  margin-right: 16px;
  color: $titles-color;
  counter-increment: section;
  content: '0' counter(section);

  @include font-style(400, 20px, 0.7);
  font-family: 'RobotoCondensed';

  @media screen and (min-width: $desctop) {
    @include font-style(400, 28px, 1.25);
    margin-right: 32px;
  }
}

.faq__text {
  color: $text-color;

  font-size: 14px;
  line-height: 1.3;


  @media screen and (min-width: $tablet) and (max-width: $pre-desctop) {
    @include font-style(400, 16px, 1.45);
  }

  @media screen and (min-width: $desctop) {
    @include font-style(600, 28px, 1.25);
  }
}

.faq__button {
  @include centered-flex(flex, center, center);
  position: absolute;
  right: 0;
  padding: 0;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  color: $titles-color;
  cursor: pointer;

  @include font-style(400, 20px, 0.7);
  font-family: RobotoCondensed;

  @media screen and (max-width: 479px) {
    margin-left: 12px;
  }

  @media screen and (min-width: $tablet) {
    right: 16px;
  }

  @media screen and (min-width: $tablet) and (max-width: $pre-desctop) {
    align-self: flex-start;
  }

  @media screen and (min-width: $desctop) {
    width: 42px;
    height: 42px;
    @include font-style(400, 28px, 0.7);
  }
}

.faq__content {
  margin-top: 10px;
  padding-left: 36px;
  padding-right: 44px;
  opacity: 1;
  max-height: 100%;
  color: $text-color;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: $desctop) {
    padding-left: 60px;
  }
}

.faq__item.hide .faq__content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}
