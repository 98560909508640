.sound-section {
  @media screen and (max-width: 767px) {
    padding-bottom: 10px;
  }
  @media screen and (min-width: 768px) {
    padding-bottom: 100px;
  }
  @media screen and (min-width: 1440px) {
    padding-bottom: 170px;
  }
}

.sound-container {
  @media screen and (min-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: 1440px) {
    padding-right: 80px;
    &.sound-list {
      padding-left: 190px;
      padding-right: 190px;
    }
  }
}

.sound-title {
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: end;
  }
}

.sound-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
  @media screen and (min-width: 1440px) {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.sound-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.sound-list__title {
  padding-bottom: 15px;
  @media screen and (min-width: 1440px) {
    padding-bottom: 20px;
  }
}

.sound-list__item {
  @media screen and (min-width: 768px) {
    max-width: 294px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 510px;
  }
  &:not(last-child) {
    @media screen and (max-width: 767px) {
      margin-bottom: 42px;
    }
  }
}
